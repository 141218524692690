<template>
    <div>
        <el-row>
            <el-col :span="24" class="title-gourp">
                <div class="pic_w">
                    <div class="txt_bg">
                        <h2>报告公示</h2>
                        <p>REPORTS</p>
                    </div>
                </div>
                <el-image class="title-image" :src="titleImage" fit="fill"></el-image>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>报告公示</el-breadcrumb-item>
                    <el-breadcrumb-item>安全评价报告详情</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row>
        </el-row>
        <el-divider></el-divider>
        <el-row>
            <el-col :span="18" :offset="3">
                <div class="detail">
                    <div class="body">
                        <table class="ports" width="100%" border="0" cellspacing="2" cellpadding="0" bgcolor="#a8c9a8">
                            <tbody>
                                <tr>
                                    <td width="18%" height="40" align="right" bgcolor="#e4f9e4">
                                        项目名称：
                                    </td>
                                    <td height="40" bgcolor="#ffffff" colspan="3">
                                        {{ zxSafetyReport.projectName }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="18%" height="40" align="right" bgcolor="#e4f9e4">
                                        评价报告提交时间：
                                    </td>
                                    <td height="40" bgcolor="#ffffff" colspan="3">
                                        {{zxSafetyReport.reportCompletionTime}}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="18%" height="150" align="right" bgcolor="#e4f9e4">
                                        项目简介：
                                    </td>
                                    <td height="40" bgcolor="#ffffff" colspan="3">
                                        {{ zxSafetyReport.projectBrief }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="18%" height="40" align="right" bgcolor="#e4f9e4">
                                        项目组长：
                                    </td>
                                    <td height="40" bgcolor="#ffffff">
                                        {{zxSafetyReport.projectLeader}}
                                    </td>
                                    <td width="18%" height="40" align="right" bgcolor="#e4f9e4">
                                        技术负责人：
                                    </td>
                                    <td height="40" bgcolor="#ffffff">
                                        {{zxSafetyReport.technicalDirector}}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="18%" height="40" align="right" bgcolor="#e4f9e4">
                                        报告编制人：
                                    </td>
                                    <td height="40" bgcolor="#ffffff" colspan="3">
                                        {{ zxSafetyReport.reportWriter }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="18%" height="40" align="right" bgcolor="#e4f9e4">
                                        报告审核人：
                                    </td>
                                    <td height="40" bgcolor="#ffffff">
                                        {{zxSafetyReport.reportReviewer}}
                                    </td>
                                    <td width="18%" height="40" align="right" bgcolor="#e4f9e4">
                                        过程控制负责人：
                                    </td>
                                    <td height="40" bgcolor="#ffffff">
                                        {{zxSafetyReport.safetyEvaluator}}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="18%" height="40" align="right" bgcolor="#e4f9e4">
                                        参与安全评价工作的安全评价师：
                                    </td>
                                    <td height="40" bgcolor="#ffffff" colspan="3">
                                        {{zxSafetyReport.safetyEngineer}}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="18%" height="40" align="right" bgcolor="#e4f9e4">
                                        参与安全评价工作的注册安全工程师：
                                    </td>
                                    <td height="40" bgcolor="#ffffff" colspan="3">
                                        {{zxSafetyReport.registeredSafetyEngineer}}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="18%" height="40" align="right" bgcolor="#e4f9e4">
                                        参与安全评价的<br> 技术专家：
                                    </td>
                                    <td height="40" bgcolor="#ffffff" colspan="3">
                                        {{ zxSafetyReport.technicalExperts }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="18%" height="40" align="right" bgcolor="#e4f9e4">
                                        到现场开展安全评价工作的人员、时间及主要任务：
                                    </td>
                                    <td height="40" bgcolor="#ffffff" colspan="3">
                                        {{ zxSafetyReport.safetyAssessment }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="18%" height="40" align="right" bgcolor="#e4f9e4">
                                        附件：
                                    </td>
                                    <td height="40" bgcolor="#ffffff" colspan="3">
                                        <img class="attachment" :src="zxSafetyReport.attachment">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            id: 0,
            titleImage:"/dyq/20231029/3fabe0a72317480581c757b096c14138.jpg",
            zxSafetyReport: {}
        }
    },
    created() {
        let that = this
        that.id = that.$route.query.id
        that.getData()
        that.updateNum()
    },
    methods: {
        getData() {
            let that = this
            that.$get("/renren-fast/pc/pcZxSafetyReport/detail", { id: that.id }).then(res => {
                if (res.code === 0) {
                    that.zxSafetyReport = res.zxSafetyReport
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
        },
        updateNum(){
            that.$get("/renren-fast/pc/pcZxSafetyReport/updateNum", { id: that.id }).then(res => {
                console.log(res);
            }).catch(err => {
                that.$notify.error(err.message)
            })   
        }
    }
}
</script>
<style>
.title-gourp {
    position: relative;
    height: 275px;
    background: #fafafa;
    border-bottom: 5px solid #63c048;
    top: -2px;
    overflow: hidden;
}

.pic_w {
    position: absolute;
    top: 70px;
    left: 200px;
    width: 1100px;
    margin: 0 auto;
    z-index: 2;
    height: inherit;
}

.txt_bg {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    width: 300px;
    height: 150px;
    border: 1px solid #fff;
}

.txt_bg>h2 {
    color: #fff;
    font-size: 50px;
    text-align: center;
    margin: 15px 0 0 0;
}

.txt_bg>p {
    color: #ddd;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    margin: 0px;
}

.title-image {
    height: 275px;
}

.el-tabs__nav-wrap::after {
    display: none;
}

.el-breadcrumb {
    font-size: 18px !important;
    line-height: 36px !important;
}

.detail {
    padding: 15px 0
}

.detail .body {
    padding: 20px;
    line-height: 26px;
    min-height: 500px
}

.detail .body h1 {
    text-align: center;
    font-weight: normal;
    color: #333;
    font-size: 28px;
    padding-top: 20px;
    line-height: 160%
}

.detail .body .lite {
    font-size: 14px;
    color: #666;
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid #ddd
}

.detail .body .bod_txt {
    padding-top: 20px
}
.attachment{
    width: 900px;
    height: 600px;
}
</style>
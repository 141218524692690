import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue' // 首页

// 关于正先
import CompanyIntroduction from '../views/CompanyIntroduction.vue' // 公司介绍
import AssetLeasing from '../views/AssetLeasing.vue' // 资产招租详情


// 资质荣誉
import Honors from '../views/Honors.vue'
// 主要业务
import ServiceArea from '../views/ServiceArea.vue' // 安全评价报告

// 报告展示
import SafetyReportList from '../views/SafetyReportList.vue' // 安全评价报告
import SafetyReportDetail from '../views/SafetyReportDetail.vue' // 安全评价详情
import HazardAssessmentDetail from '../views/HazardAssessmentDetail.vue' // 安全评价详情

// 恒通公告
import EveroneList from '../views/EveroneList.vue' // 恒通公告
import EveroneDetail from '../views/EveroneDetail.vue' // 恒通公告详情

import PartyBuilding from '../views/PartyBuilding.vue' // 党的建设
import PartyBuildingDetail from '../views/PartyBuildingDetail.vue' // 党的建设


import Media from '../views/Media.vue' // 媒体中心
import CompanyDynamic from '../views/CompanyDynamic.vue' // 媒体中心

// 人才招聘

import TalentRecruitment from '../views/TalentRecruitment.vue' // 媒体中心

import Contact from '../views/Contact.vue' // 媒体中心

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: Home
  },
  {
    path: '/companyIntroduction',
    name: '公司介绍',
    component: CompanyIntroduction
  },
  {
    path: '/assetLeasing',
    name: '资产招租详情',
    component: AssetLeasing
  },
  {
    path: '/honors',
    name: '资质荣誉',
    component: Honors
  },
  {
    path: '/safetyReportList',
    name: '安全评价报告',
    component: SafetyReportList
  },
  {
    path: '/safetyReportDetail',
    name: '安全评价报告详情',
    component: SafetyReportDetail
  },
  {
    path: '/hazardAssessmentDetail',
    name: '重大危险源评估报告',
    component: HazardAssessmentDetail
  },
  {
    path: '/serviceArea',
    name: '服务范围',
    component: ServiceArea
  },
  {
    path: '/everoneList',
    name: '恒通公告',
    component: EveroneList
  },
  {
    path: '/everoneDetail',
    name: '恒通公告详情',
    component: EveroneDetail
  },
  {
    path: '/partyBuilding',
    name: '党的建设',
    component: PartyBuilding
  },
  {
    path: '/partyBuildingDetail',
    name: '党的建设详情',
    component: PartyBuildingDetail
  },
  {
    path: '/media',
    name: '媒体中心',
    component: Media
  },
  {
    path: '/companyDynamic',
    name: '公司动态',
    component: CompanyDynamic
  },
  {
    path: '/talentRecruitment',
    name: '人才招聘',
    component: TalentRecruitment
  },
  {
    path: '/contact',
    name: '人才招聘',
    component: Contact
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: '/home/',
  routes
})

export default router

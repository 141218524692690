<template>
    <div>
        <el-row>
            <el-col :span="24" class="title-gourp">
                <div class="pic_w">
                    <div class="txt_bg">
                        <h2>报告公示</h2>
                        <p>REPORTS</p>
                    </div>
                </div>
                <el-image class="title-image" :src="titleImage" fit="fill"></el-image>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>报告公示</el-breadcrumb-item>
                    <el-breadcrumb-item>安全评价报告详情</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row>
        </el-row>
        <el-divider></el-divider>
        <el-row>
            <el-col :span="18" :offset="3">
                <div class="detail">
                    <div class="body">
                        <table class="ports" width="100%" border="0" cellspacing="2" cellpadding="0" bgcolor="#a8c9a8">
                            <tr>
                                <td width="20%" height="20" align="center" bgcolor="#e4f9e4">
                                    项目名称
                                </td>
                                <td height="20" bgcolor="#ffffff">
                                    {{ zxHazardAssessmentReport.title }}
                                </td>
                            </tr>
                            <tr>
                                <td width="20%" height="150" align="center" bgcolor="#e4f9e4">
                                    项目简介
                                </td>
                                <td height="40" bgcolor="#ffffff">
                                    {{ zxHazardAssessmentReport.projectIntroduction }}
                                </td>
                            </tr>
                            <tr>
                                <td width="20%" height="20" align="center" bgcolor="#e4f9e4">
                                    项目负责人
                                </td>
                                <td height="20" bgcolor="#ffffff">
                                    {{ zxHazardAssessmentReport.projectLeader }}
                                </td>
                            </tr>
                            <tr>
                                <td width="20%" height="20" align="center" bgcolor="#e4f9e4">
                                    级别
                                </td>
                                <td height="20" bgcolor="#ffffff">
                                    {{ zxHazardAssessmentReport.level }}
                                </td>
                            </tr>
                            <tr>
                                <td width="20%" height="20" align="center" bgcolor="#e4f9e4">
                                    报告完成时间
                                </td>
                                <td height="20" bgcolor="#ffffff">
                                    {{ zxHazardAssessmentReport.reportCompletionTime }}
                                </td>
                            </tr>
                            <tr>
                                <td width="20%" height="20" align="center" bgcolor="#e4f9e4">
                                    项目组成人员
                                </td>
                                <td height="20" bgcolor="#ffffff">
                                    {{ zxHazardAssessmentReport.projectComponent }}
                                </td>
                            </tr>
                            <tr>
                                <td width="20%" height="20" align="center" bgcolor="#e4f9e4">
                                    附件
                                </td>
                                <td height="20" bgcolor="#ffffff">
                                    <div @click="donwloadFile()">
                                        {{ zxHazardAssessmentReport.attachment }}
                                    </div>

                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            titleImage: "/dyq/20231029/3fabe0a72317480581c757b096c14138.jpg",
            id: 0,
            zxHazardAssessmentReport: {}
        }
    },
    created() {
        let that = this
        that.id = this.$route.query.id
        that.init()
    },
    methods: {
        init() {
            let that = this
            that.$get("/renren-fast/pc/pcZxHazardAssessmentReport/detail", { id: that.id }).then(res => {
                if (res.code === 0) {
                    that.zxHazardAssessmentReport = res.zxHazardAssessmentReport
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
        },
        donwloadFile() {

        }
    }
}
</script>
<style>
.title-gourp {
    position: relative;
    height: 275px;
    background: #fafafa;
    border-bottom: 5px solid #63c048;
    top: -2px;
    overflow: hidden;
}

.pic_w {
    position: absolute;
    top: 70px;
    left: 200px;
    width: 1100px;
    margin: 0 auto;
    z-index: 2;
    height: inherit;
}

.txt_bg {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    width: 300px;
    height: 150px;
    border: 1px solid #fff;
}

.txt_bg>h2 {
    color: #fff;
    font-size: 50px;
    text-align: center;
    margin: 15px 0 0 0;
}

.txt_bg>p {
    color: #ddd;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    margin: 0px;
}

.title-image {
    height: 275px;
}

.el-tabs__nav-wrap::after {
    display: none;
}

.el-breadcrumb {
    font-size: 18px !important;
    line-height: 36px !important;
}

.detail {
    padding: 15px 0
}

.detail .body {
    padding: 20px;
    line-height: 26px;
    min-height: 500px
}

.detail .body h1 {
    text-align: center;
    font-weight: normal;
    color: #333;
    font-size: 28px;
    padding-top: 20px;
    line-height: 160%
}

.detail .body .lite {
    font-size: 14px;
    color: #666;
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid #ddd
}

.detail .body .bod_txt {
    padding-top: 20px
}

.attachment {
    width: 900px;
    height: 600px;
}
</style>
<template>
    <div>
        <el-row>
            <el-col :span="24" class="title-gourp">
                <div class="pic_w">
                </div>
                <el-image class="title-image" :src="titleImage" fit="cover"></el-image>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="10" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/partyBuilding?type=1', }">党的建设</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ zxPartyBuilding.title }}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row>
            <el-col :span="24">
                <div class="text-center">
                    {{ zxPartyBuilding.title }}
                </div>
            </el-col>
            <el-col :span="24">
                <div class="pr-center">
                    发布时间:{{ zxPartyBuilding.releaseTime }}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    浏览量:{{ zxPartyBuilding.pageView }}
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="18" :offset="4">
                <div class="contanier" v-html="contanier"></div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            type: 0,
            titleImage: "/dyq/20231214/24d674c598cc4767b7ae5002e38853eb.jpg",
            tabName: [
                "党的建设",
                "员工风采"
            ],
            contanier: {},
            pageNum: 1,
            pageSum: 10,
            total: 0,
            zxPartyBuilding: {}
        }
    },
    created() {
        let that = this
        that.id = that.$route.query.id
        that.getData()
    },
    methods: {
        getData() {
            let that = this
            that.$get("/renren-fast/pc/pcZxPartyBuilding/detail", { id: that.id }).then(res => {
                if (res.code === 0) {
                    that.zxPartyBuilding = res.zxPartyBuilding
                    that.contanier = res.zxPartyBuilding.brief
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
        }
    }
}
</script>
<style>
.text-center {
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 30px;
}
.pr-center{
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 15px;
}
</style>
<template>
    <div>
        <el-row>
            <el-col :span="24" class="title-gourp">
                <div class="pic_w">
                </div>
                <el-image class="title-image" :src="titleImage" fit="cover"></el-image>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="10" :offset="4">
                <el-tabs @tab-click="handleClick" v-model="type">
                    <el-tab-pane label="信息预披露" name="1"></el-tab-pane>
                    <el-tab-pane label="拍卖公告" name="2"></el-tab-pane>
                    <el-tab-pane label="拍卖公示" name="3"></el-tab-pane>
                    <el-tab-pane label="招标公告" name="4"></el-tab-pane>
                    <el-tab-pane label="招标公示" name="5"></el-tab-pane>
                </el-tabs>
            </el-col>
            <el-col :span="6" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>恒通公告</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ tabName[type - 1] }}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <div class="flex flex-row search-group">
                    <el-input placeholder="标题" style="width: 300px;" class="search-title" v-model="key_word"></el-input>
                    <el-button icon="el-icon-search" class="search-button" @click="serach"></el-button>
                </div>
            </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row>
            <el-col :span="12" :offset="3">
                <el-card v-for="(item, index) in announceList" :key="index">
                    <div @click="goDetail(item.id)" class="flex flex-col">
                        <div class="flex flex-row">
                            <div class="title-fonts">
                                {{ item.title }}
                            </div>
                            <el-image class="new-icon" v-if="item.isNew === 1" src="/dyq/20231219/e98b8c3f6d8c4bdabe64b67839d0f143.gif"></el-image>
                        </div>
                        <div class="btom">
                            发布时间:{{ parseTime(item.releaseTime, "{y}-{m}-{d}") }}
                        </div>
                        <div class="btom">
                            浏览人数:{{ item.pageView }}
                        </div>
                    </div>

                </el-card>
                <el-row>
                    <el-col :span="2" :offset="22">
                        <el-pagination background layout="prev, pager, next" :total="total"
                            @current-change="handleCurrentChange" :current-page.sync="pageNum">
                        </el-pagination>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { parseTime } from "../utils/date.js";
export default {
    data() {
        return {
            type: 0,
            titleImage: "/dyq/20231128/e9f8d40abb1d431e99be03c813b44293.jpg",
            tabName: [
                "信息预披露",
                "拍卖公告",
                "拍卖公示",
                "招标公告",
                "招标公示"
            ],
            pageNum: 1,
            pageSum: 10,
            total: 0,
            announceList: [],
            key_word:""
        }
    },
    created() {
        let that = this
        that.type = that.$route.query.type
        that.init()
    },
    methods: {
        serach(){
            let that = this
            let params = {
                page: that.pageNum,
                limit: that.pageSum,
                key: that.key_word,
                type: that.type
            }
            that.$get("/renren-fast/pc/pcZxHtAnnounce/list", params).then(res => {
                if (res.code === 0) {
                    // that.announceList = res.result.list
                    // that.total = res.result.total
                    that.announceList = res.page.list
                    that.total = res.page.totalCount
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
        },
        parseTime(time, cFormat) {
            return parseTime(time, cFormat)
        },
        init() {
            let that = this
            let params = {
                page: that.pageNum,
                limit: that.pageSum,
                key: that.key_word,
                type: that.type
            }
            that.$get("/renren-fast/pc/pcZxHtAnnounce/list", params).then(res => {
                if (res.code === 0) {
                    // that.announceList = res.result.list
                    // that.total = res.result.total
                    that.announceList = res.page.list
                    that.total = res.page.totalCount
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
            // that.$get("/renren-fast/pc/pcZxHtAnnounce/list", { type: that.type, pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
            //     if (res.code === 0) {
            //         that.announceList = res.result.list
            //         that.total = res.result.total
            //     } else {
            //         that.$notify.error("暂无数据！")
            //     }
            // }).catch(err => {
            //     that.$notify.error(err.message)
            // })
        },
        handleClick(event) {
            let that = this
            let type = event.name
            that.pageNum = 1
            that.type = type
            let params = {
                page: that.pageNum,
                limit: that.pageSum,
                key: "",
                type
            }
            that.$get("/renren-fast/pc/pcZxHtAnnounce/list", params).then(res => {
                if (res.code === 0) {
                    // that.announceList = res.result.list
                    // that.total = res.result.total
                    that.announceList = res.page.list
                    that.total = res.page.totalCount
                    
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
            // that.$get("/renren-fast/pc/pcZxHtAnnounce/list", { type: type, pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
            //     if (res.code === 0) {
            //         that.announceList = res.result.list
            //         that.total = res.result.total
            //     } else {
            //         that.$notify.error("暂无数据！")
            //     }
            // }).catch(err => {
            //     that.$notify.error(err.message)
            // })
        },
        handleCurrentChange(event) {
            let that = this
            let params = {
                page: event,
                limit: that.pageSum,
                key: "",
                type:that.type
            }
            that.$get("/renren-fast/pc/pcZxHtAnnounce/list", params).then(res => {
                if (res.code === 0) {
                    // that.announceList = res.result.list
                    // that.total = res.result.total
                    that.announceList = res.page.list
                    that.total = res.page.totalCount
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
            // that.$get("/renren-fast/pc/pcZxHtAnnounce/list", { type: that.type, pageNum: event, pageSum: that.pageSum }).then(res => {
            //     if (res.code === 0) {
            //         that.announceList = res.result.list
            //         that.total = res.result.total
            //     } else {
            //         that.$notify.error("暂无数据！")
            //     }
            // }).catch(err => {
            //     that.$notify.error(err.message)
            // })
        },
        goDetail(id) {
            let that = this
            that.$router.push({ path: "everoneDetail", query: { id } })
        }
    }
}
</script>
<style>
.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.title-fonts {
    background: url('../assets/i1.png') no-repeat center left;
    padding-left: 30px;
    font-weight: normal;
    height: 42px;
    line-height: 42px;
}

.title-fonts:hover {
    cursor: pointer;
    color: #ff6c00;
}

.btom {
    line-height: 30px;
    font-size: 12px;
    color: #666;
}
.new-icon{
    height: 14px;
}
</style>
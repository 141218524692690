<template lang="">
    <div>
        <el-row>
            <el-col :span="24" class="title-gourp">
                <div class="pic_w">
                    <div class="txt_bg">
                        <h2>关于正先</h2>
                        <p>ABOUT</p>
                    </div>
                </div>
                <el-image class="title-image" :src="titleImage" fit="cover"></el-image>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="10" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>关于正先</el-breadcrumb-item>
                    <el-breadcrumb-item>{{  }}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row>
            <el-col :span="24">
                <div class="text-center">
                    {{ zxAssetLeasing.title }}
                </div>
            </el-col>
            <el-col :span="24">
                <div class="pr-center">
                    
                    发布时间:{{ parseTime(zxAssetLeasing.publishTime, "{y}-{m}-{d}") }}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    浏览量:{{ zxAssetLeasing.pageView }}
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="18" :offset="4">
                <div class="contanier" v-html="contanier"></div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { parseTime } from "../utils/date.js";
export default {
    data(){
        return{
            titleImage: "/dyq/20231029/6c0abe90bf854542bacec9d539b7182a.jpg",
            contanier: "",
            id:"",
            zxAssetLeasing:{},
            
        }
    },
    mounted(){
        this.id = this.$route.query.id
        this.getData()
    },
    methods: {
        parseTime(time, cFormat) {
            return parseTime(time, cFormat)
        },
        getData() {
            let that = this
            that.$get("/renren-fast/pc/pcZxAssetLeasing/detail", { id: that.id }).then(res => {
                if (res.code === 0) {
                    that.zxAssetLeasing = res.zxAssetLeasing
                    that.contanier = res.zxAssetLeasing.brief
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
        }
    }
}
</script>
<style lang="">
    
</style>
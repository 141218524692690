<template>
    <div>
        <el-row>
            <el-col :span="24" class="title-gourp">
                <div class="pic_w">
                    <div class="txt_bg">
                        <h2>资质荣誉</h2>
                        <p>HONORS</p>
                    </div>
                </div>
                <el-image class="title-image" :src="titleImage" fit="cover"></el-image>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="10" :offset="4">
                <el-tabs @tab-click="handleClick" v-model="type">
                    <el-tab-pane label="企业荣誉" name="1"></el-tab-pane>
                    <el-tab-pane label="企业资质" name="2"></el-tab-pane>
                </el-tabs>
            </el-col>
            <el-col :span="6" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>资质荣誉</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ tabName[type - 1] }}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="18" :offset="4">
                <div class="image-list">
                    <el-image 
                    v-for="(item, index) in image_list" :key="index"
                    :src="item.imageUrl" class="image-detail"></el-image>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            type: 0,
            titleImage: "/dyq/20231125/c4401d3cbc64432e9cd37d0f952bc271.jpg",
            tabName: [
                "企业荣誉",
                "企业资质"
            ],
            image_list: []
        }
    },
    created() {
        let that = this
        that.type = that.$route.query.type
        that.init()
    },
    methods: {
        init() {
            let that = this
            that.$get("/renren-fast/pc/pcZxHonors/list", { type: that.type }).then(res => {
                that.image_list = res.ZxHonors
            })
        },
        handleClick(val) {
            let that = this
            that.type = val.name
            that.$get("/renren-fast/pc/pcZxHonors/list", { type: that.type }).then(res => {
                that.image_list = res.ZxHonors
            })
        }
    }
}
</script>
<style>
.image-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.image-detail{
    width: 450px;
    margin-right: 60px;
    margin-bottom: 60px;
    padding:10px;
    border: 1px solid #ddd;
}
</style>
import axios from 'axios'

// 测试环境需要关闭
// const base_url = 'http://175.27.254.104:8080'
const base_url = ''

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */


export function get(url, queryParams) {
    return new Promise((resolve, reject) => {
        axios.get(base_url + url, {
            params: queryParams
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error)
        })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, postData) {
    return new Promise((resolve, reject) => {
        axios.post(base_url + url, JSON.stringify(postData))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    })
}
<template>
    <div class="company-introduct-pannel">
        <el-row>
            <el-col :span="24" class="title-gourp">
                <div class="pic_w">
                    <div class="txt_bg">
                        <h2>关于正先</h2>
                        <p>ABOUT</p>
                    </div>
                </div>
                <el-image class="title-image" :src="titleImage" fit="cover"></el-image>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="10" :offset="4">
                <el-tabs @tab-click="handleClick" v-model="type">
                    <el-tab-pane label="公司介绍" name="1"></el-tab-pane>
                    <el-tab-pane label="组织架构" name="2"></el-tab-pane>
                    <el-tab-pane label="技术团队" name="3"></el-tab-pane>
                    <el-tab-pane label="专业设备" name="4"></el-tab-pane>
                    <el-tab-pane label="企业文化" name="5"></el-tab-pane>
                    <el-tab-pane label="资产招租" name="6"></el-tab-pane>
                </el-tabs>
            </el-col>
            <el-col :span="6" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>关于正先</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ tabName[type - 1] }}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="18" :offset="2" v-if="type !== '3' && type !== '6'">
                <div class="contanier" v-html="contanier"></div>
            </el-col>
            <el-col :span="18" :offset="4" v-if="type === '3'">
                <el-table :data="technicalTeam" stripe style="width: 100%">
                    <el-table-column prop="id" label="序号" width="50">
                    </el-table-column>
                    <el-table-column prop="name" label="姓名" width="70">
                    </el-table-column>
                    <el-table-column prop="education" label="学历" width="70">
                    </el-table-column>
                    <el-table-column prop="profession" label="专业">
                    </el-table-column>
                    <el-table-column prop="certificate_num" label="安全评价资格证书编号">
                    </el-table-column>
                    <el-table-column prop="level" label="职业资格等级">
                    </el-table-column>
                    <el-table-column prop="pro_rank" label="职称">
                    </el-table-column>
                    <el-table-column prop="memo" label="备注">
                    </el-table-column>
                </el-table>
                <el-col :span="2" :offset="18">
                    <el-pagination background layout="prev, pager, next" :total="total"
                        @current-change="handleCurrentChange">
                    </el-pagination>
                </el-col>
            </el-col>
            <el-col v-if="type === '6'">
                <el-row>
                    <el-col :span="24">
                        <div class="flex flex-row search-group">
                            <el-input placeholder="标题" style="width: 300px;" class="search-title"></el-input>
                            <el-button icon="el-icon-search" class="search-button"></el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-divider :span="24"></el-divider>
                <el-row>
                    <el-col :span="12" :offset="2">
                        <el-card v-for="(item, index) in assetLeasing" :key="index">
                            <div @click="goDetail(item.id)">
                                <!-- <div>
                                    {{ item.title }}
                                </div>
                                <div>
                                    发布时间:{{ item.publish_time }}
                                </div>
                                <div>
                                    浏览人数:{{ item.page_view }}
                                </div> -->
                                <div class="flex flex-row">
                                    <div class="title-fonts">
                                        {{ item.title }}
                                    </div>
                                </div>
                                <div class="btom">
                                    发布时间:{{ parseTime(item.publishTime, "{y}-{m}-{d}") }}
                                </div>
                            </div>

                        </el-card>
                        <el-row>
                            <el-col :span="2" :offset="22">
                                <el-pagination background layout="prev, pager, next" :total="total"
                                    @current-change="handleCurrentChange">
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </el-col>

                </el-row>
            </el-col>

        </el-row>

    </div>
</template>
<script>
import { parseTime } from "../utils/date.js";
export default {
    data() {
        return {
            titleImage: "/dyq/20231029/6c0abe90bf854542bacec9d539b7182a.jpg",
            contanier: "",
            type: 0,
            tabName: [
                "公司介绍",
                "组织架构",
                "技术团队",
                "专业设备",
                "企业文化",
                "资产招租"
            ],
            technicalTeam: [],
            assetLeasing: [],
            pageNum: 1,
            pageSum: 10,
            total: 0
        }
    },
    created() {
        let that = this
        that.type = that.$route.query.type
        that.init()
    },
    methods: {
        parseTime(time, cFormat) {
            return parseTime(time, cFormat)
        },
        init() {
            let that = this
            if (that.type === '3') {
                that.$get("/renren-fast/pc/pcZxTechnicalTeam/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
                    if (res.result) {
                        that.technicalTeam = res.result.list,
                            that.total = res.result.listNum
                    }
                })

            } else if (that.type === '6') {
                let params ={
                    page: that.pageNum,
                    limit: that.pageSum
                }
                that.$get("/renren-fast/pc/pcZxAssetLeasing/list", params).then(res => {
                    if (res.code === 0) {
                        that.assetLeasing = res.page.list
                        that.total = res.page.totalCount
                    }
                })
            }
            else {
                that.$get("/renren-fast/pc/pcZxAbout/list", { type: that.type }).then(res => {
                    that.contanier = res.result.brief
                })
            }
        },
        handleClick(val) {
            let that = this
            if (val.name === '3') {
                that.$get("/renren-fast/pc/pcZxTechnicalTeam/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
                    if (res.result) {
                        that.technicalTeam = res.result.list
                        that.total = res.result.listNum
                    }
                })

            } else if (val.name === '6') {
                let params ={
                    page: that.pageNum,
                    limit: that.pageSum
                }
                that.$get("/renren-fast/pc/pcZxAssetLeasing/list", params).then(res => {
                    if (res.code === 0) {
                        that.assetLeasing = res.page.list
                        that.total = res.page.totalCount
                    }
                })
            } else {
                that.$get("/renren-fast/pc/pcZxAbout/list", { type: val.name }).then(res => {
                    if (res.result) {
                        that.contanier = res.result.brief
                    }
                })
            }
        },
        handleCurrentChange(event) {
            let that = this
            if (that.type === '3') {
                that.$get("/renren-fast/pc/pcZxTechnicalTeam/list", { pageNum: event, pageSum: that.pageSum }).then(res => {
                    if (res.result) {
                        that.technicalTeam = res.result.list
                        that.total = res.result.listNum
                    }
                })
            } else if (that.type === '6') {
                let params ={
                    page: event,
                    limit: that.pageSum
                }
                that.$get("/renren-fast/pc/pcZxAssetLeasing/list", params).then(res => {
                    if (res.code === 0) {
                        that.assetLeasing = res.page.list
                        that.total = res.page.totalCount
                    }
                })
                // that.$get("/renren-fast/pc/pcZxAssetLeasing/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
                //     if (res.result) {
                //         that.assetLeasing = res.result.list
                //         that.total = res.result.total
                //     }
                // })
            }
        },
        goDetail(id){
            let that = this
            that.$router.push({ path: "assetLeasing", query: { id } })
        }
    }
}
</script>
<style>
.contanier {
    margin-top: 70px;
}
</style>
<template>
  <div>

    <div class="carousel-panel">
      <el-carousel ref="carousel" class="image-pannel">
        <el-carousel-item v-for="(item, index) in imageList" :key="index">
          <el-image class="image-item" :src="item.imageUrl"></el-image>
        </el-carousel-item>
      </el-carousel>
      <div class="block-img">
        <el-image :src="blockImg" mode="scaleToFill" />
      </div>
    </div>
    <el-row class="info-pannel">
      <el-col :span="12">
        <div class="flex flex-col parter-group">
          <div class="flex flex-row parter-title">
            <span style="font-size:22px;margin-bottom: 15px;">合作伙伴</span>
          </div>
          <div class="flex flex-row parter-images">
            <el-image v-for="(item, index) in partner" :key="index" :src="item.logoImage" style="height:60px"
              fit="fill"></el-image>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="flex flex-col report-group">
          <div class="flex flex-row report-title">
            <span style="font-size:22px">报告公示</span>
          </div>
          <div class="flex flex-col report-articles">
            <div class="flex flex-row" v-for="(item, index) in reportList" :key="index">
              <div class="info-line" @click="goDetail(item.id)">

                {{ parseTime(item.publishTime, "{y}-{m}-{d}") }}
                {{ item.projectName }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { parseTime } from "../utils/date.js";
export default {
  data() {
    return {
      partner: [],
      blockImg: require('../assets/min_bg.png'),
      imageList: [
      ],
      reportList: []
    }
  },
  created() {
    let that = this
    that.$get("/renren-fast/pc/slideshow/list").then(res => {
      if (res.code === 0) {
        that.imageList = res.zxSlideshow
      } else {
        that.$notify.error("暂无数据！")
      }
    }).catch(err => {
      that.$notify.error(err.message)
    })
    that.$get("/renren-fast/pc/pcZxPartners/list").then(res => {
      if (res.code === 0) {
        that.partner = res.result
      } else {
        that.$notify.error("暂无数据！")
      }
    }).catch(err => {
      that.$notify.error(err.message)
    })
    let params = {
      page: 1,
      limit: 3,
      key: ""
    }
    // that.$get("/renren-fast/pc/pcZxSafetyReport/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
    that.$get("/renren-fast/pc/pcZxSafetyReport/list", params).then(res => {
      if (res.code === 0) {
        // that.reportList = res.result.list
        // that.total = res.result.total
        that.reportList = res.page.list
        that.total = res.page.totalCount
      } else {
        that.$notify.error("暂无数据！")
      }
    }).catch(err => {
      that.$notify.error(err.message)
    })
    // that.$get("/renren-fast/pc/pcZxSafetyReport/list", { pageNum: 1, pageSum: 3 }).then(res => {
    //   if (res.code === 0) {
    //     that.reportList = res.result.list
    //     that.reportList.map(item => {
    //       let date = new Date(parseInt(item.publish_time));
    //       let Year = date.getFullYear();
    //       let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    //       let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    //       item.DateTime = Year + '-' + Moth + '-' + Day
    //     })
    //   } else {
    //     that.$notify.error("暂无数据！")
    //   }
    // }).catch(err => {
    //   that.$notify.error(err.message)
    // })
  },
  methods: {
    parseTime(time, cFormat) {
      return parseTime(time, cFormat)
    },
    goDetail(id) {
      let that = this
      that.$router.push({ path: "SafetyReportDetail", query: { id } })
    },
  }
}
</script>
<style>
.carousel-panel {
  position: relative;
}

.el-carousel__container {
  height: 530px !important;
}

.image-pannel {
  height: 530px;
}

.image-item {
  width: 100%;
  height: 530px;
}

.block-img {
  z-index: 99;
  position: absolute !important;
  top:408px;
  width: 100%;
  height: 150px
}

.parter-group {
  justify-content: flex-start;
}

.parter-title {
  justify-content: center;
}

.parter-images {
  justify-content: space-around;

}

.report-group {
  justify-content: flex-start;
  align-items: center;
}

.report-articles {
  justify-content: space-around;
}

.info-line {
  border-bottom: 1px solid #000000;
  cursor: pointer;
}
.info-pannel{
  margin-top: 5px;
}
</style>
<template>
    <div>
        <el-row>
            <el-col :span="24" class="title-gourp">
                <el-col :span="24" class="title-gourp">
                    <div class="pic_w">
                        <div class="txt_bg">
                            <h2>联系方式</h2>
                            <p>CONTACT</p>
                        </div>
                    </div>
                    <el-image class="title-image" :src="titleImage" fit="cover"></el-image>
                </el-col>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="10" :offset="4">
                <el-tabs v-model="type">
                    <el-tab-pane label="联系方式" name="1"></el-tab-pane>
                </el-tabs>
            </el-col>
            <el-col :span="6" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>联系方式</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-divider></el-divider>
        <div class="info-form">
            <el-row :gutter="12" class="info-row">
            <el-col :span="3" :offset="0">地址:</el-col>
            <el-col :span="6" :offset="0">{{ adress }}</el-col>
        </el-row>
        <el-row :gutter="12" class="info-row">
            <el-col :span="3" :offset="0">电话:</el-col>
            <el-col :span="6" :offset="0">{{ telphone }}</el-col>
        </el-row>
        <el-row :gutter="12" class="info-row">
            <el-col :span="3" :offset="0">传真:</el-col>
            <el-col :span="6" :offset="0">{{ fax }}</el-col> 
        </el-row>
        </div>

                    <!-- <el-col :span="12" :offset="0">电话:</el-col>
            <el-col :span="12" :offset="0">{{ phone }}</el-col>
            <el-col :span="12" :offset="0">传真:</el-col>
            <el-col :span="12" :offset="0">{{ fax }}</el-col> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            type:'1',
            titleImage: "/dyq/20231220/ef81b61449844dfa9732e1c289f5f208.jpg",
            adress: "",
            telphone: "",
            fax: ""
        }
    },
    created() {
        let that = this
        that.init()
    },
    methods: {
        init() {
            let that = this
            that.$get("/renren-fast/pc/PcContact/list").then(res => {
                if (res.code === 0) {
                    res.result.map(item => {
                        if (item.paramKey === 'adress') {
                            that.adress = item.paramValue
                        }
                        if (item.paramKey === 'phone') {
                            that.telphone = item.paramValue
                        }
                        if (item.paramKey === 'fax') {
                            that.fax = item.paramValue
                        }
                    })

                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
        }
    }
}
</script>
<style>
.info-form{
    font-size: 25px;
}
.info-row{
    border-bottom: 1px dotted #ddd;
    padding-bottom: 15px;
}
</style>
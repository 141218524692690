<template>
    <div>
        <el-row>
            <el-col :span="24" class="title-gourp">
                <div class="pic_w">
                    <div class="txt_bg">
                        <h2>报告公示</h2>
                        <p>REPORTS</p>
                    </div>
                </div>
                <el-image class="title-image" :src="titleImage" fit="cover"></el-image>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6" :offset="4">
                <el-tabs @tab-click="handleClick" v-model="type">
                    <el-tab-pane label="安全评价报告" name="1"></el-tab-pane>
                    <el-tab-pane label="重大危险源评估报告" name="2"></el-tab-pane>
                </el-tabs>
            </el-col>
            <el-col :span="6" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>报告公示</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ tabName }}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <div class="flex flex-row search-group">
                    <el-input placeholder="标题" style="width: 300px;" class="search-title" v-model="key_word"></el-input>
                    <el-button icon="el-icon-search" class="search-button" @click="serach"></el-button>
                </div>
            </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row v-if="type === '1'">
            <el-col :span="12" :offset="3">
                <el-card v-for="(item, index) in reportList" :key="index">
                    <div @click="goDetail1(item.id)">
                        <!-- <div>
                            {{ item.project_name }}
                        </div>
                        <div>
                            发布时间:{{ parseTime(item.publish_time, "{y}-{m}-{d}") }}
                        </div>
                        <div>
                            浏览人数:{{ item.view_count }}
                        </div> -->
                        <div class="flex flex-row">
                            <div class="title-fonts">
                                {{ item.projectName }}
                            </div>
                            <el-image class="new-icon" v-if="item.isNew === 1"
                                src="/dyq/20231219/e98b8c3f6d8c4bdabe64b67839d0f143.gif"></el-image>
                        </div>
                        <div class="btom">
                            发布时间:{{ parseTime(item.publishTime, "{y}-{m}-{d}") }}
                        </div>
                        <div class="btom">
                            浏览人数:{{ item.viewCount }}
                        </div>
                    </div>

                </el-card>
                <el-row>
                    <el-col :span="2" :offset="22">
                        <el-pagination background layout="prev, pager, next" :total="total"
                            @current-change="handleCurrentChange">
                        </el-pagination>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row v-if="type === '2'">
            <el-col :span="12" :offset="3">
                <el-card v-for="(item, index) in reportList" :key="index">
                    <div @click="goDetail2(item.id)">
                        <!-- <div>
                            {{ item.title }}
                        </div>
                        <div>
                            发布时间:{{ parseTime(item.release_date, "{y}-{m}-{d}") }}
                        </div>
                        <div>
                            浏览人数:{{ item.page_view }}
                        </div> -->
                        <div class="flex flex-row">
                            <div class="title-fonts">
                                {{ item.title }}
                            </div>
                            <el-image class="new-icon" v-if="item.isNew === 1"
                                src="/dyq/20231219/e98b8c3f6d8c4bdabe64b67839d0f143.gif"></el-image>
                        </div>
                        <div class="btom">
                            发布时间:{{ parseTime(item.releaseDate, "{y}-{m}-{d}") }}
                        </div>
                        <div class="btom">
                            浏览人数:{{ item.pageView }}
                        </div>
                    </div>

                </el-card>
                <el-row>
                    <el-col :span="2" :offset="22">
                        <el-pagination background layout="prev, pager, next" :total="total"
                            @current-change="handleCurrentChange2" :current-page.sync="pageNum">
                        </el-pagination>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { parseTime } from "../utils/date.js";
export default {
    data() {
        return {
            titleImage: "/dyq/20231029/3fabe0a72317480581c757b096c14138.jpg",
            tabName: "安全评价报告",
            reportList: [],
            pageNum: 1,
            pageSum: 10,
            total: 0,
            type: 0,
            key_word: ""
        }
    },
    created() {
        let that = this
        that.type = this.$route.query.type
        that.getData()
    },
    methods: {
        serach() {
            let that = this
            if (that.type === '1') {
                let params = {
                    page: that.pageNum,
                    limit: that.pageSum,
                    key: this.key_word
                }
                // that.$get("/renren-fast/pc/pcZxSafetyReport/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
                that.$get("/renren-fast/pc/pcZxSafetyReport/list", params).then(res => {
                    if (res.code === 0) {
                        // that.reportList = res.result.list
                        // that.total = res.result.total
                        that.reportList = res.page.list
                        that.total = res.page.totalCount
                    } else {
                        that.$notify.error("暂无数据！")
                    }
                }).catch(err => {
                    that.$notify.error(err.message)
                })
            } else if (that.type === '2') {
                let params = {
                    page: that.pageNum,
                    limit: that.pageSum,
                    key: ""
                }
                // that.$get("/renren-fast/pc/pcZxHazardAssessmentReport/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
                that.$get("/renren-fast/pc/pcZxHazardAssessmentReport/list", params).then(res => {
                    if (res.code === 0) {
                        // that.reportList = res.result.list
                        // that.total = res.result.total
                        that.reportList = res.page.list
                        that.total = res.page.totalCount
                    } else {
                        that.$notify.error("暂无数据！")
                    }
                }).catch(err => {
                    that.$notify.error(err.message)
                })
            }
        },
        parseTime(time, cFormat) {
            return parseTime(time, cFormat)
        },
        getData() {
            let that = this
            if (that.type === '1') {
                let params = {
                    page: that.pageNum,
                    limit: that.pageSum,
                    key: this.key_word
                }
                // that.$get("/renren-fast/pc/pcZxSafetyReport/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
                that.$get("/renren-fast/pc/pcZxSafetyReport/list", params).then(res => {
                    if (res.code === 0) {
                        // that.reportList = res.result.list
                        // that.total = res.result.total
                        that.reportList = res.page.list
                        that.total = res.page.totalCount
                    } else {
                        that.$notify.error("暂无数据！")
                    }
                }).catch(err => {
                    that.$notify.error(err.message)
                })
            } else if (that.type === '2') {
                let params = {
                    page: that.pageNum,
                    limit: that.pageSum,
                    key: this.key_word
                }
                // that.$get("/renren-fast/pc/pcZxHazardAssessmentReport/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
                that.$get("/renren-fast/pc/pcZxHazardAssessmentReport/list", params).then(res => {
                    if (res.code === 0) {
                        // that.reportList = res.result.list
                        // that.total = res.result.total
                        that.reportList = res.page.list
                        that.total = res.page.totalCount
                    } else {
                        that.$notify.error("暂无数据！")
                    }
                }).catch(err => {
                    that.$notify.error(err.message)
                })
            }

        },
        handleClick(event) {
            let that = this
            that.tabName = event.label
            that.pageNum = 1
            that.type = event.name
            if (that.type === '1') {
                // that.$get("/renren-fast/pc/pcZxSafetyReport/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
                //     if (res.code === 0) {
                //         that.reportList = res.result.list
                //         that.total = res.result.total
                //     } else {
                //         that.$notify.error("暂无数据！")
                //     }
                // }).catch(err => {
                //     that.$notify.error(err.message)
                // })
                let params = {
                    page: that.pageNum,
                    limit: that.pageSum,
                    key: this.key_word
                }
                // that.$get("/renren-fast/pc/pcZxSafetyReport/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
                that.$get("/renren-fast/pc/pcZxSafetyReport/list", params).then(res => {
                    if (res.code === 0) {
                        // that.reportList = res.result.list
                        that.reportList = res.page.list
                        // that.total = res.result.total
                        that.total = res.page.totalCount
                    } else {
                        that.$notify.error("暂无数据！")
                    }
                }).catch(err => {
                    that.$notify.error(err.message)
                })
            } else if (that.type === '2') {
                let params = {
                    page: that.pageNum,
                    limit: that.pageSum,
                    key: this.key_word
                }
                // that.$get("/renren-fast/pc/pcZxHazardAssessmentReport/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
                that.$get("/renren-fast/pc/pcZxHazardAssessmentReport/list", params).then(res => {
                    if (res.code === 0) {
                        // that.reportList = res.result.list
                        // that.total = res.result.total
                        that.reportList = res.page.list
                        that.total = res.page.totalCount
                    } else {
                        that.$notify.error("暂无数据！")
                    }
                }).catch(err => {
                    that.$notify.error(err.message)
                })
                // that.$get("/renren-fast/pc/pcZxHazardAssessmentReport/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
                //     if (res.code === 0) {
                //         that.reportList = res.result.list
                //         that.total = res.result.total
                //     } else {
                //         that.$notify.error("暂无数据！")
                //     }
                // }).catch(err => {
                //     that.$notify.error(err.message)
                // })
            }
        },
        goDetail1(id) {
            let that = this
            that.$router.push({ path: "SafetyReportDetail", query: { id } })
        },
        goDetail2(id) {
            let that = this
            that.$router.push({ path: "hazardAssessmentDetail", query: { id } })
        },
        handleCurrentChange(event) {
            let that = this
            let params = {
                page: event,
                limit: that.pageSum,
                key: this.key_word
            }
            // that.$get("/renren-fast/pc/pcZxSafetyReport/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
            that.$get("/renren-fast/pc/pcZxSafetyReport/list", params).then(res => {
                if (res.code === 0) {
                    // that.reportList = res.result.list
                    that.reportList = res.page.list
                    // that.total = res.result.total
                    that.total = res.page.totalCount
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
            // that.$get("/renren-fast/pc/pcZxSafetyReport/list", { pageNum: event, pageSum: that.pageSum }).then(res => {
            //     if (res.code === 0) {
            //         that.reportList = res.result.list
            //         that.total = res.result.total
            //     } else {
            //         that.$notify.error("暂无数据！")
            //     }
            // }).catch(err => {
            //     that.$notify.error(err.message)
            // })
        },
        handleCurrentChange2(event) {
            let that = this
            let params = {
                page: event,
                limit: that.pageSum,
                key: this.key_word
            }
            // that.$get("/renren-fast/pc/pcZxHazardAssessmentReport/list", { pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
            that.$get("/renren-fast/pc/pcZxHazardAssessmentReport/list", params).then(res => {
                if (res.code === 0) {
                    // that.reportList = res.result.list
                    // that.total = res.result.total
                    that.reportList = res.page.list
                    that.total = res.page.totalCount
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
            // that.$get("/renren-fast/pc/pcZxHazardAssessmentReport/list", { pageNum: event, pageSum: that.pageSum }).then(res => {
            //     if (res.code === 0) {
            //         that.reportList = res.result.list
            //         that.total = res.result.total
            //     } else {
            //         that.$notify.error("暂无数据！")
            //     }
            // }).catch(err => {
            //     that.$notify.error(err.message)
            // })
        }
    }
}
</script>
<style>
.title-gourp {
    position: relative;
    height: 275px;
    background: #fafafa;
    border-bottom: 5px solid #63c048;
    top: -2px;
    overflow: hidden;
}

.pic_w {
    position: absolute;
    top: 70px;
    left: 200px;
    width: 1100px;
    margin: 0 auto;
    z-index: 2;
    height: inherit;
}

.txt_bg {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    width: 300px;
    height: 150px;
    border: 1px solid #fff;
}

.txt_bg>h2 {
    color: #fff;
    font-size: 50px;
    text-align: center;
    margin: 15px 0 0 0;
}

.txt_bg>p {
    color: #ddd;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    margin: 0px;
}

.title-image {
    height: 275px;
}

.el-tabs__nav-wrap::after {
    display: none;
}

.search-title {
    margin-right: 35px;
}

.search-button {
    background-color: #63c048 !important;
}

.el-icon-search {
    color: #fafafa;
}

.el-breadcrumb {
    font-size: 18px !important;
    line-height: 36px !important;
}

.search-group {
    margin-left: 250px;
}

.el-card {
    width: 1200px;
    margin-top: 15px;
}

.el-pagination {
    margin-top: 15px;
    margin-bottom: 35px;
}
</style>
<template>
  <div id="app">
    <div class="index">
      <el-container>
        <el-header v-show="headerShow">
          <el-row>
            <el-col :span="10">
              <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
                <el-row>
                  <el-col :span="5">
                    <!-- <el-menu-item index="1"> -->
                    <div class="index-icon">
                      <div class="flex flex-col title-group">
                        <router-link to="/" style="text-decoration: none;color: black;" class="title-CN">
                          <div class="title-CN">首页</div>
                          <div class="title-EN">INDEX</div>
                        </router-link>
                      </div>
                    </div>
                    <!-- </el-menu-item> -->
                  </el-col>
                  <el-col :span="5">
                    <el-submenu index="2">
                      <template slot="title">
                        <div class="flex flex-col title-group">
                          <div class="title-CN">关于正先</div>
                          <div class="title-EN">ABOUT</div>
                        </div>
                      </template>
                      <el-menu-item index="2-1"><router-link to="/companyIntroduction?type=1"
                          style="text-decoration: none;color: black;" class="title-CN">公司介绍</router-link></el-menu-item>
                      <el-menu-item index="2-2"><router-link to="/companyIntroduction?type=2"
                          style="text-decoration: none;color: black;" class="title-CN">组织架构</router-link></el-menu-item>
                      <el-menu-item index="2-3"><router-link to="/companyIntroduction?type=3"
                          style="text-decoration: none;color: black;" class="title-CN">技术团队</router-link></el-menu-item>
                      <el-menu-item index="2-4"><router-link to="/companyIntroduction?type=4"
                          style="text-decoration: none;color: black;" class="title-CN">专业设备</router-link></el-menu-item>
                      <el-menu-item index="2-5"><router-link to="/companyIntroduction?type=5"
                          style="text-decoration: none;color: black;" class="title-CN">企业文化</router-link></el-menu-item>
                      <el-menu-item index="2-6"><router-link to="/companyIntroduction?type=6"
                          style="text-decoration: none;color: black;" class="title-CN">资产招租</router-link></el-menu-item>
                    </el-submenu>
                  </el-col>
                  <el-col :span="5">
                    <el-submenu index="3">
                      <template slot="title">
                        <div class="flex flex-col title-group">
                          <div class="title-CN">主要业务</div>
                          <div class="title-EN">SERVICES</div>
                        </div>
                      </template>
                      <el-menu-item index="3-1"><router-link to="/serviceArea?type=1"
                          style="text-decoration: none;color: black;" class="title-CN">服务领域</router-link></el-menu-item>
                      <el-menu-item index="3-2"><router-link to="/serviceArea?type=2"
                          style="text-decoration: none;color: black;" class="title-CN">服务流程</router-link></el-menu-item>
                      <el-menu-item index="3-3"><router-link to="/serviceArea?type=3"
                          style="text-decoration: none;color: black;" class="title-CN">合作伙伴</router-link></el-menu-item>
                      <el-menu-item index="3-4"><router-link to="/serviceArea?type=4"
                          style="text-decoration: none;color: black;" class="title-CN">主要业绩</router-link></el-menu-item>
                    </el-submenu>
                  </el-col>
                  <el-col :span="5">
                    <el-submenu index="4">
                      <template slot="title">
                        <div class="flex flex-col title-group">
                          <div class="title-CN">资质荣誉</div>
                          <div class="title-EN">HONORS</div>
                        </div>
                      </template>
                      <el-menu-item index="4-1"><router-link to="/honors?type=1"
                          style="text-decoration: none;color: black;" class="title-CN">企业荣誉</router-link></el-menu-item>
                      <el-menu-item index="4-2"><router-link to="/honors?type=2"
                          style="text-decoration: none;color: black;" class="title-CN">企业资质</router-link></el-menu-item>
                    </el-submenu>
                  </el-col>
                  <el-col :span="4">
                    <el-submenu index="5">
                      <template slot="title">
                        <div class="flex flex-col title-group">
                          <div class="title-CN">党的建设</div>
                          <div class="title-EN">PARTY</div>
                        </div>
                      </template>
                      <el-menu-item index="5-1"><router-link to="/partyBuilding?type=1"
                          style="text-decoration: none;color: black;" class="title-CN">党的建设</router-link></el-menu-item>
                      <el-menu-item index="5-2"><router-link to="/partyBuilding?type=2"
                          style="text-decoration: none;color: black;" class="title-CN">员工风采</router-link></el-menu-item>
                    </el-submenu>
                  </el-col>
                </el-row>
              </el-menu>
            </el-col>
            <el-col :span="4">
              <el-row :span="24" type="flex" justify="center">
                <img src="./assets/top_logo.png">
              </el-row>
            </el-col>
            <el-col :span="10">
              <el-menu class="el-menu-demo" mode="horizontal">
                <el-row>
                  <el-col :span="5">
                    <el-submenu index="6">
                      <template slot="title">
                        <div class="flex flex-col title-group">
                          <div class="title-CN">报告公示</div>
                          <div class="title-EN">REPORTS</div>
                        </div>
                      </template>
                      <el-menu-item index="6-1"><router-link to="/safetyReportList?type=1"
                          style="text-decoration: none;">安全评价报告</router-link></el-menu-item>
                      <el-menu-item index="6-2"><router-link to="/safetyReportList?type=2"
                          style="text-decoration: none;">重大危险源评估报告</router-link></el-menu-item>
                    </el-submenu>
                  </el-col>
                  <el-col :span="5">
                    <el-submenu index="7">
                      <template slot="title">
                        <div class="flex flex-col title-group">
                          <div class="title-CN">恒通公告</div>
                          <div class="title-EN">EVERONE</div>
                        </div>
                      </template>
                      <el-menu-item index="7-1"><router-link to="/everoneList?type=1"
                          style="text-decoration: none;">信息预披露</router-link></el-menu-item>
                      <el-menu-item index="7-2"><router-link to="/everoneList?type=2"
                          style="text-decoration: none;">拍卖公告</router-link></el-menu-item>
                      <el-menu-item index="7-3"><router-link to="/everoneList?type=3"
                          style="text-decoration: none;">拍卖公示</router-link></el-menu-item>
                      <el-menu-item index="7-4"><router-link to="/everoneList?type=4"
                          style="text-decoration: none;">招标公告</router-link></el-menu-item>
                      <el-menu-item index="7-5"><router-link to="/everoneList?type=5"
                          style="text-decoration: none;">招标公示</router-link></el-menu-item>
                    </el-submenu>
                  </el-col>
                  <el-col :span="4">
                    <el-submenu index="8">
                      <template slot="title">
                        <div class="flex flex-col title-group">
                          <div class="title-CN">媒体中心</div>
                          <div class="title-EN">MEDIA</div>
                        </div>
                      </template>
                      <el-menu-item index="8-1"><router-link to="/media?type=1"
                          style="text-decoration: none;">热点聚焦</router-link></el-menu-item>
                      <el-menu-item index="8-2"><router-link to="/media?type=2"
                          style="text-decoration: none;">公司动态</router-link></el-menu-item>
                    </el-submenu>
                  </el-col>
                  <el-col :span="4">
                    <!-- <el-menu-item index="9"> -->
                    <div class="index-icon">
                      <router-link to="/talentRecruitment?type=1" style="text-decoration: none;color: black;">
                        <div class="flex flex-col title-group">
                          <div class="title-CN">人才招聘</div>
                          <div class="title-EN">JOBS</div>
                        </div>
                      </router-link>
                    </div>
                    <!-- </el-menu-item> -->
                  </el-col>
                  <el-col :span="4">
                    <!-- <el-menu-item index="10"> -->
                    <div class="index-icon">
                      <router-link to="/contact" style="text-decoration: none;color: black;">
                        <div class="flex flex-col title-group">
                          <div class="title-CN">联系方式</div>
                          <div class="title-EN">CONTACT</div>
                        </div>
                      </router-link>
                    </div>
                    <!-- </el-menu-item> -->
                  </el-col>
                </el-row>
              </el-menu>
            </el-col>
          </el-row>
        </el-header>
        <el-main ref="scrollContainer"><router-view :key="$route.fullPath" /></el-main>
        <el-footer v-show="footShow">
          <div class="p_bottom">
            <div class="txt">
              福州正先安全科技咨询服务有限公司 | 地址：福州市五一北路88号八一服务社12#楼01第四、五层 | 电话：0591-87270709<br>
              版权所有©正先公司 | 备案号：<a href="https://beian.miit.gov.cn/" class="txt" style="color:white"
                target="_blank">闽ICP备17021622号-1</a></div>
          </div>
        </el-footer>
      </el-container>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headerShow: true,
      footShow: true,
      activeIndex: '1',
    }
  },
  watch:{
    '$route':'init'
  },
  mounted() {
    let that = this
    that.init()
  },
  // mounted() {
  //   if (this.$route.path === '/everoneList' || this.$route.path === '/safetyReportList' ||
  //     this.$route.path === '/SafetyReportDetail' || this.$route.path === '/hazardAssessmentDetail' ||
  //     this.$route.path === '/everoneDetail') {
  //     this.footShow = false
  //   } else {
  //     this.footShow = true
  //   }
  // },
  methods: {
    init() {
      if (this.$route.path === '/everoneList' || this.$route.path === '/safetyReportList' ||
        this.$route.path === '/SafetyReportDetail' || this.$route.path === '/hazardAssessmentDetail' ||
        this.$route.path === '/everoneDetail') {
        window.addEventListener('scroll', this.scrollToTop, true);
        this.footShow = false
      } else {
        window.removeEventListener("scroll", this.scrollToTop, true);
        this.footShow = true
      }
    },
    scrollToTop(event) {
      let pageYOffset = event.target.scrollTop;
      if (pageYOffset > 50) {
        this.headerShow = false
      } else {
        this.headerShow = true
      }
      let scrollHeight = this.$refs.scrollContainer.$el.scrollHeight
      if (pageYOffset > scrollHeight - 715) {
        this.footShow = true
      } else {
        this.footShow = false
      }
    }
  }
}
</script>
<style>
.app {
  overflow: hidden;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.index {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.el-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
}

.el-header {
  z-index: 9;
  height: 93px !important;
}

.el-icon-arrow-down:before {
  display: none;
}

.el-main {
  height: 100%;
  overflow: auto;
  padding: 0px !important;
  height: 53px;
}

.el-menu-item {
  margin: 0px;
}

.el-menu--popup {
  color: #FFFFFF !important;
  background-color: #41b900 !important;
}

.el-menu--horizontal>ul>li,
.el-menu--horizontal>ul>li>a {
  display: flex;
  width: 100%;
  height: 100%;
  color: #FFFFFF !important;
  background-color: #41b900 !important;
}

.el-menu--horizontal>ul>li>a:hover {
  color: #41b900 !important;
  background-color: #FFFFFF !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px !important;
  height: 53px;
}

.title-group {
  margin-top: 15px;
}

.title-CN {
  font-size: 16px;
  height: 30px;
}

.title-CN {
  font-size: 16px;
  height: 30px;
  color: #41b900 !important;
  background-color: #FFFFFF !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #FFFFFF !important;
}

.el-submenu__title:hover {
  background-color: #FFFFFF !important;
}

.title-EN {
  color: #999;
  font-size: 13px;
}

.el-footer {
  display: flex;
  height: 4vh;
  width: 100%;
  align-items: center;
}

.p_bottom {
  height: 60px;
  border-top: 10px solid #d2e8cc;
  background: #1f8c00;
  position: absolute;
  bottom: 0px;
  width: 98%;
}

.p_bottom .txt {
  padding-top: 20px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  line-height: 20px
}

.el-footer {
  padding: 0 !important;
}

.index-icon {
  margin-top: 43px;
  margin-left: 35px;
}
</style>

<template>
    <div>
        <el-row>
            <el-col :span="24" class="title-gourp">
                <div class="pic_w">
                    <div class="txt_bg">
                        <h2>主要业务</h2>
                        <p>SERVICE</p>
                    </div>
                </div>
                <el-image class="title-image" :src="titleImage" fit="cover"></el-image>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="10" :offset="4">
                <el-tabs @tab-click="handleClick" v-model="type">
                    <el-tab-pane label="服务领域" name="1"></el-tab-pane>
                    <el-tab-pane label="服务流程" name="2"></el-tab-pane>
                    <el-tab-pane label="合作伙伴" name="3"></el-tab-pane>
                    <el-tab-pane label="主要业绩" name="4"></el-tab-pane>
                </el-tabs>
            </el-col>
            <el-col :span="6" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>主要业务</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ tabName[type] }}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="18" :offset="4">
                <div class="contanier" v-html="contanier"></div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            titleImage:"/dyq/20231103/af4e48b14f3a42f3a8b6d04a2ebf6abe.jpg",
            type: 0,
            pageNum: 1,
            pageSum: 10,
            total: 0,
            contanier:{},
            tabName:[
                "服务领域",
                "服务流程",
                "合作伙伴",
                "主要业绩"
            ]
        }
    },
    created() {
        let that = this
        that.type = this.$route.query.type
        that.init()
    }, methods: {
        init() {
            let that = this
            that.$get("/renren-fast/pc/pcZxMainService/list", { type: that.type }).then(res => {
                that.contanier = res.ZxMainService.brief
                
            })
        },
        handleClick(e){
            let that = this
            that.$get("/renren-fast/pc/pcZxMainService/list", { type: e.name }).then(res => {
                that.contanier = res.ZxMainService.brief
                
            })
        }
    }
}
</script>
<style></style>
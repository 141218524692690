<template lang="">
    <div>
        <el-row>
            <el-col :span="24" class="title-gourp">
                <div class="pic_w">
                    <div class="txt_bg">
                        <h2>媒体中心</h2>
                        <p>MEDIA</p>
                    </div>
                </div>
                <el-image class="title-image" :src="titleImage" fit="cover"></el-image>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="10" :offset="4">
                <el-tabs @tab-click="handleClick" v-model="type">
                    <el-tab-pane label="热点聚焦" name="1"></el-tab-pane>
                    <el-tab-pane label="公司动态" name="2"></el-tab-pane>
                </el-tabs>
            </el-col>
            <el-col :span="6" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>媒体中心</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ tabName[type - 1] }}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12" :offset="3">
                <el-card v-for="(item, index) in list" :key="index">
                    <div @click="goDetail(item.id)">
                        <div class="flex flex-row">
                            <div class="title-fonts">
                                {{ item.title }}
                            </div>
                        </div>
                        <div class="btom">
                            发布时间:{{ parseTime(item.releaseTime, "{y}-{m}-{d}") }}
                        </div>
                    </div>

                </el-card>
                <el-row>
                    <el-col :span="2" :offset="22">
                        <el-pagination background layout="prev, pager, next" :total="total"
                            @current-change="handleCurrentChange2">
                        </el-pagination>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { parseTime } from "../utils/date.js";
export default {
    data() {
        return {
            type: 0,
            titleImage: "/dyq/20231216/af2fdae4a8104aa492e0232bcdad57c9.jpg",
            tabName: [
                "热点聚焦",
                "公司动态"
            ],
            list: [],
            pageNum: 1,
            pageSum: 10,
            total: 0
        }
    },
    created(){
        let that = this
        that.type = this.$route.query.type
        that.init()
    },
    methods:{
        parseTime(time, cFormat) {
            return parseTime(time, cFormat)
        },
        init(){
            let that = this
            if(that.type === "2"){
                let params = {
                page: this.pageNum,
                limit: that.pageSum,
                key: ""
            }
                that.$get("/renren-fast/pc/zxcompanydynamic/list", params).then(res => {
                    if (res.code === 0) {
                        // that.reportList = res.result.list
                        // that.total = res.result.total
                        that.list = res.page.list
                        that.total = res.page.totalCount
                    } else {
                        that.$notify.error("暂无数据！")
                    }
                }).catch(err => {
                    that.$notify.error(err.message)
                })
            }else{
                that.list = []
                that.total = 0
            }
        },
        handleClick(event){
            let that = this
            that.type = event.name
            if(that.type === '2'){
                let params = {
                    page: that.pageNum,
                    limit: that.pageSum,
                    key: ""
                }
                that.$get("/renren-fast/pc/zxcompanydynamic/list", params).then(res => {
                    if (res.code === 0) {
                        that.list = res.page.list
                        that.total = res.page.totalCount
                    } else {
                        that.$notify.error("暂无数据！")
                    }
                }).catch(err => {
                    that.$notify.error(err.message)
                })
            }else{
                that.list = []
                that.total = 0
            }
        },
        handleCurrentChange2(){
            let that = this
            if(that.type === '2'){
                let params = {
                    page: that.pageNum,
                    limit: that.pageSum,
                    key: ""
                }
                that.$get("/renren-fast/pc/zxcompanydynamic/list", params).then(res => {
                    if (res.code === 0) {
                        that.list = res.page.list
                        that.total = res.page.totalCount
                    } else {
                        that.$notify.error("暂无数据！")
                    }
                }).catch(err => {
                    that.$notify.error(err.message)
                })
            }else{
                that.list = []
                that.total = 0
            }
        },
        goDetail(id){
            let that = this
            that.$router.push({ path: "companyDynamic", query: { id } })
        }
    }
}
</script>
<style lang="">
    
</style>
<template lang="">
    <div>
        <el-row>
            <el-col :span="24" class="title-gourp">
                <el-col :span="24" class="title-gourp">
                <div class="pic_w">
                    <div class="txt_bg">
                        <h2>人才招聘</h2>
                        <p>JOBS</p>
                    </div>
                </div>
                <el-image class="title-image" :src="titleImage" fit="cover"></el-image>
            </el-col>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="10" :offset="4">
                <el-tabs @tab-click="handleClick" v-model="type">
                    <el-tab-pane label="人才招聘" name="1"></el-tab-pane>
                </el-tabs>
            </el-col>
            <el-col :span="6" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>人才招聘</el-breadcrumb-item>
                    <!-- <el-breadcrumb-item>{{ tabName[type - 1] }}</el-breadcrumb-item> -->
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <div class="flex flex-row search-group">
                    <el-input placeholder="标题" style="width: 300px;" class="search-title" v-model="key_word"></el-input>
                    <el-button icon="el-icon-search" class="search-button" @click="serach"></el-button>
                </div>
            </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row>
            <el-col :span="12" :offset="3">
                <el-card v-for="(item, index) in list" :key="index">
                        <li>
                            <div class="h">
                                <div class="fr" @click="showpannel(item)">
                                        {{item.show === 1?"收起":"展开"}}</div>
                                {{item.title}}
                                </div>
                            <div class="xiangxi" v-show="item.show">
                                <div class="in_bar">
                                    <div class="lis1">
                                        招聘职位</div>
                                    <div class="lis2">
                                        {{item.jobVacancy}}</div>
                                    <div class="lis1">
                                        招聘人数</div>
                                    <div class="lis2">
                                        {{item.recruitingNumbers}}人</div>
                                    <div class="lis1">
                                        职位薪资</div>
                                    <div class="lis2">
                                        {{item.positionSalary}}</div>
                                </div>
                                <div class="in_bar">
                                    <div class="lis1">
                                        工作地点</div>
                                    <div class="lis2" style="width: 30%">
                                        {{item.workplace}}</div>
                                </div>
                                <div class="pan">
                                    <strong>职位描述：</strong><br>
                                    {{item.jobDescription}}
                                    </div>
                            </div>
                        </li>
                </el-card>
                <el-row>
                    <el-col :span="2" :offset="22">
                        <el-pagination background layout="prev, pager, next" :total="total" 
                            :page-size="pageSum"
                            @current-change="handleCurrentChange" :current-page.sync="pageNum">
                        </el-pagination>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            titleImage: "/dyq/20231219/b788b1339da245feba9b74de929cf7de.jpg",
            type: 0,
            pageNum: 1,
            pageSum: 3,
            total: 0,
            list: []
        }
    },
    mounted() {
        let that = this
        that.type = this.$route.query.type
        this.getData()
    },
    methods: {
        serach(){

        },
        showpannel(item){
            let that = this
            item.show = item.show === 1?0:1
            let new_list = []
            that.list.map ((item2) =>{
                if(item2.id === item.id){
                    item2 = item
                    new_list.push(item2)
                }else{
                    new_list.push(item2)
                }
            })
            that.list = new_list
        },
        getData() {
            let that = this
            let params = {
                page: that.pageNum,
                limit: that.pageSum,
            }
            that.$get("/renren-fast/pc/zxtalentrecruitment/list", params).then(res => {
                if (res.code === 0) {
                    that.list = res.page.list
                    that.total = res.page.totalCount
                    that.list.map((item, index) => {
                        if (index === 0) {
                            item.show = 1
                        } else {
                            item.show = 0
                        }
                    })
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
        },
        handleClick() {

        },
        handleCurrentChange(event) {
            let that = this
            that.pageNum = event
            let params = {
                page: that.pageNum,
                limit: that.pageSum,
            }
            that.$get("/renren-fast/pc/zxtalentrecruitment/list", params).then(res => {
                if (res.code === 0) {
                    that.list = res.page.list
                    that.total = res.page.totalCount
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
        }
    }
}
</script>
<style>
li {
    /* box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1); */
    /* border: 1px solid #ddd; */
    padding: 10px;
    margin-bottom: 20px;
    list-style: none;
}

li .h {
    font-size: 20px;
    line-height: 34px;
    padding: 10px 0;
}

li .fr {
    float: right;
    font-size: 14px;
    cursor: pointer;
    background: #999;
    border-radius: 3px;
    color: #fff;
    padding: 0 10px;
}

.in_bar {
    overflow: hidden;
    border: 1px solid #ddd;
    line-height: 50px;
    margin-bottom: 1px;
}

li .pan {
    padding: 15px;
    line-height: 30px;
    border: 1px solid #ddd;
    border-top: none;
    background: #fafafa;
}
.in_bar .lis1 {
    float: left;
    background: #ddd;
    text-align: center;
    width: 15%;
}
.in_bar .lis2 {
    float: left;
    width: 18%;
    text-align: center;
}
</style>
<template>
    <div>
        <el-row>
            <el-col :span="24" class="title-gourp">
                <el-image class="title-image" :src="titleImage" fit="cover"></el-image>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="10" :offset="4">
                <el-tabs @tab-click="handleClick" v-model="type">
                    <el-tab-pane label="党的建设" name="1"></el-tab-pane>
                    <el-tab-pane label="员工风采" name="2"></el-tab-pane>
                </el-tabs>
            </el-col>
            <el-col :span="6" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>党的建设</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ tabName[type - 1] }}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12" :offset="3">
                <el-card v-for="(item, index) in partyBuilding" :key="index">
                    <div @click="goDetail(item.id)">
                        <div class="title-fonts">
                            {{ item.title }}
                        </div>
                        <!-- <div>
                            发布时间:{{ parseTime(item.release_time, "{y}-{m}-{d}") }}
                        </div>
                        <div>
                            浏览人数:{{ item.page_view }}
                        </div> -->
                    </div>

                </el-card>
                <el-row>
                    <el-col :span="2" :offset="22">
                        <el-pagination background layout="prev, pager, next" :total="total"
                            @current-change="handleCurrentChange">
                        </el-pagination>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { parseTime } from "../utils/date.js";
export default {
    data() {
        return {
            type: 0,
            titleImage: "/dyq/20231214/24d674c598cc4767b7ae5002e38853eb.jpg",
            tabName: [
                "党的建设",
                "员工风采"
            ],
            partyBuilding: [],
            pageNum: 1,
            pageSum: 10,
            total: 0
        }
    },
    created(){
        let that = this
        that.type = that.$route.query.type
        that.init()
    },
    methods:{
        parseTime(time, cFormat){
            return parseTime(time, cFormat)
        },
        init() {
            let that = this
            that.$get("/renren-fast/pc/pcZxPartyBuilding/list", { type: that.type, pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
                if (res.code === 0) {
                    that.partyBuilding = res.result.list
                    that.total = res.result.total
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
        },
        handleCurrentChange(event){
            let that = this
            that.$get("/renren-fast/pc/pcZxPartyBuilding/list", { type: that.type, pageNum: event, pageSum: that.pageSum }).then(res => {
                if (res.code === 0) {
                    that.announceList = res.result.list
                    that.total = res.result.total
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
        },
        handleClick(event){
            let that = this
            let type = event.name
            that.$get("/renren-fast/pc/pcZxPartyBuilding/list", { type: type, pageNum: that.pageNum, pageSum: that.pageSum }).then(res => {
                if (res.code === 0) {
                    that.partyBuilding = res.result.list
                    that.total = res.result.total
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
        },
        goDetail(id){
            let that = this
            that.$router.push({ path: "partyBuildingDetail", query: { id } })
        }
    }
}
</script>
<style>
</style>
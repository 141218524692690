<template>
    <div>
        <el-row>
            <el-col :span="24" class="title-gourp">
                <div class="pic_w">
                </div>
                <el-image class="title-image" :src="titleImage" fit="cover"></el-image>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="10" :offset="4">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/EveroneList?type=1', }">恒通公告</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ zxHtAnnounce.title }}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row>
            <el-col :span="24">
                <div class="text-center">
                    {{ zxHtAnnounce.title }}
                </div>
            </el-col>
            <el-col :span="24">
                <div class="pr-center">
                    发布时间:{{ zxHtAnnounce.releaseTime }}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    浏览量:{{ zxHtAnnounce.pageView }}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button @click="openPrint()">打印</button>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="18" :offset="4">
                <div class="contanier" id="printContent" v-html="contanier"></div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { parseTime } from "../utils/date.js";
export default {
    data() {
        return {
            type: 0,
            titleImage: "/dyq/20231128/e9f8d40abb1d431e99be03c813b44293.jpg",
            tabName: [
                "信息预披露",
                "拍卖公告",
                "拍卖公示",
                "招标公告",
                "招标公示"
            ],
            contanier: {},
            pageNum: 1,
            pageSum: 10,
            total: 0,
            zxHtAnnounce: {}
        }
    },
    created() {
        let that = this
        that.id = that.$route.query.id
        that.getData()
    },
    methods: {
        openPrint() {
            let content = document.getElementById("printContent").innerHTML
            printJS({
                printable: content,// // 文档来源：pdf或图像的url，html元素的id或json数据的对象
                type: 'raw-html', // 可打印的类型。可用的打印选项包括：pdf，html，图像，json和raw-html。
                targetStyles: ['*'] // 库在打印HTML元素时处理任何样式
            })
        },
        getData() {
            let that = this
            that.$get("/renren-fast/pc/pcZxHtAnnounce/detail", { id: that.id }).then(res => {
                if (res.code === 0) {
                    that.zxHtAnnounce = res.zxHtAnnounce
                    that.contanier = res.zxHtAnnounce.brief
                } else {
                    that.$notify.error("暂无数据！")
                }
            }).catch(err => {
                that.$notify.error(err.message)
            })
        },
        updateNum() {
            that.$get("/renren-fast/pc/pcZxSafetyReport/updateNum", { id: that.id }).then(res => {
                console.log(res);
            }).catch(err => {
                that.$notify.error(err.message)
            })
        }
    }
}
</script>
<style>
.text-center {
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 30px;
    text-align: center;
}

.pr-center {
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 15px;
    border-bottom: 1px solid #ddd;
    padding: 15px;
}
</style>